import React, { Fragment, PureComponent } from 'react'
import { InputGroup, Input, Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { CustomAsyncSelect, PricingModelSelect } from 'shared/forms'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class StorageHistorySearchAndFilters extends PureComponent {
    constructor(props) {
        super(props)
        this.dateFormat = 'yyyy/MM/dd'
        this.minDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
        )
    }

    render() {
        const {
            onSearchClientName,
            onSearchNameKeyDown,
            onSearchDate,
            onSearchMatchingPricing,
            onSearchStatus,
            loadPricingModels,
            dealStatusSelected,
            t,
        } = this.props

        return (
            <Fragment>
                <Row className="mt-4">
                    <Col xs={12} md={12} lg={12} className="mb-2">
                        <InputGroup>
                            <Input
                                id="search"
                                placeholder={t('inputSearch.placeholder')}
                                onKeyDown={onSearchNameKeyDown}
                                onChange={(e) =>
                                    onSearchClientName(e.target.value)
                                }
                                className="form-control"
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="mt-2" style={{ marginBottom: 50 + 'px' }}>
                    <Col xs={12} md={4} lg={4} className="mt-2 mt-md-0">
                        <DatePicker
                            disabledKeyboardNavigation
                            dateFormat={this.dateFormat}
                            selectsRange={true}
                            monthsShown={2}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            onChange={(value) =>
                                onSearchDate(value[0], value[1])
                            }
                            isClearable={true}
                            className="form-control"
                            maxDate={new Date()}
                            minDate={this.minDate}
                            placeholderText={t('dateRangePicker.placeholder', {
                                dateFormat: this.dateFormat,
                            })}
                        />
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                        <CustomAsyncSelect
                            onChange={onSearchStatus}
                            id="status"
                            name="statusName"
                            placeholder={t('status.placeholder')}
                            data={[
                                {
                                    label: t('status.all'),
                                    value: 'all',
                                },
                                { label: t('status.accept'), value: 'accept' },
                                { label: 'Rejected', value: 'reject' },
                                {
                                    label: t('status.invalidProposal'),
                                    value: 'invalidProposal',
                                },
                                {
                                    label: t('status.maintenance'),
                                    value: 'maintenance',
                                },
                                {
                                    label: t('status.rateLimit'),
                                    value: 'rateLimit',
                                },
                                {
                                    label: t('status.storageAcceptanceLogic'),
                                    value: 'storageAcceptanceLogic',
                                },
                                {
                                    label: t('status.noFundsLeft'),
                                    value: 'noFundsLeft',
                                },
                                {
                                    label: t('status.noSpaceLeft'),
                                    value: 'noSpaceLeft',
                                },
                            ]}
                            defaultValue={
                                dealStatusSelected === 'all'
                                    ? { label: t('status.all'), value: 'all' }
                                    : { label: dealStatusSelected, value: dealStatusSelected }
                            }
                        />
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                        <PricingModelSelect
                            onChange={onSearchMatchingPricing}
                            loadPricingModels={loadPricingModels}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default withTranslation('StorageHistorySearchAndFilters')(
    StorageHistorySearchAndFilters
)
