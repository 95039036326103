export const generateRandomString = (length) => {
    return Math.random().toString(20).substr(2, length)
}

export const trimString = (str) => {
    if (str.length > 15) {
        return (
            str.substring(0, 5) +
            ' [...] ' +
            str.substring(str.length - 5, str.length)
        )
    } else {
        return str
    }
}
